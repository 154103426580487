import React from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#___gatsby');

type ModalProps = {
  isVisible: boolean;
  onClose?: () => void;
  children: React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({ isVisible, onClose, children }) => (
  <ReactModal
    className="bg-none"
    isOpen={isVisible}
    onRequestClose={onClose}
    contentLabel="Example Modal"
  >
    <div className="absolute top-1/2 left-1/2 h-[90%] w-fit -translate-x-1/2 -translate-y-1/2">
      {children}
    </div>
  </ReactModal>
);

export default Modal;
