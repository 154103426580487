import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { BiLinkExternal } from 'react-icons/bi';

import Button from '../components/Button';
import Container from '../components/Container';
import CV from '../components/content.tsx/CV';
import ShortProfile from '../components/content.tsx/ShortProfile';
import Modal from '../components/Modal';

const IndexPage: React.FC = () => {
  const [isFatherAndSonModalVisible, setIsFatherAndSonModalVisible] =
    React.useState(false);
  const [isWeddingModalVisible, setIsWeddingModalVisible] =
    React.useState(false);
  const [isFatherAndBrotherModalVisible, setIsFatherAndBrotherModalVisible] =
    React.useState(false);
  const [isHusbandAndWifeModalVisible, setIsHusbandAndWifeModalVisible] =
    React.useState(false);

  return (
    <main>
      <title>Armin Schöne</title>

      <div className="space-y-14">
        {/* Headline */}
        <Container>
          <div className="space-y-6 border-b border-stone-400 pt-16 pb-10">
            <h1 className="text-center font-serif text-6xl text-stone-600">
              Armin Schöne
            </h1>
            <span className="block w-full text-center text-xl font-light text-stone-600">
              Ein erfülltes Leben zwischen Wirtschaft und Forschung
            </span>
          </div>
        </Container>

        {/* About */}
        <Container>
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-2">
            <StaticImage
              src="../images/portrait.jpg"
              alt="Portraitfoto"
              className="aspect-[1/1] rounded shadow"
            />
            <div className="space-y-2">
              <ShortProfile />
            </div>
          </div>
        </Container>

        {/* Autobiography */}
        <div className="bg-stone-700 py-8">
          <Container>
            <div className="flex w-full justify-center">
              <Button
                theme="dark"
                icon={<AiOutlineDownload />}
                linkTo="https://www.dropbox.com/s/iue2y0w9kkhlnie/Autobiographie%20AJS%20mit%20Anhang.pdf"
              >
                Autobiografie herunterladen
              </Button>
            </div>
          </Container>
        </div>

        {/* Main */}
        <Container>
          <div className="space-y-2 text-justify">
            <CV />
          </div>
        </Container>

        {/* Images */}
        <div className="bg-stone-700 py-8">
          <Container>
            <div className="grid grid-cols-5 gap-4">
              <div onClick={() => setIsFatherAndSonModalVisible(true)}>
                <StaticImage
                  src="../images/father-and-son.jpg"
                  alt="Vater und Sohn"
                  className="aspect-[2/3] cursor-pointer rounded shadow transition hover:scale-105 hover:shadow-lg"
                />
              </div>
              <div onClick={() => setIsWeddingModalVisible(true)}>
                <StaticImage
                  src="../images/wedding.jpg"
                  alt="Hochzeit"
                  className="aspect-[2/3] cursor-pointer rounded shadow transition hover:scale-105 hover:shadow-lg"
                />
              </div>
              <div onClick={() => setIsFatherAndBrotherModalVisible(true)}>
                <StaticImage
                  src="../images/father-son-and-brother.jpg"
                  alt="Vater, Sohn und Bruder"
                  className="aspect-[2/3] cursor-pointer rounded shadow transition hover:scale-105 hover:shadow-lg"
                />
              </div>
              <div
                className="col-span-2"
                onClick={() => setIsHusbandAndWifeModalVisible(true)}
              >
                <StaticImage
                  src="../images/husband-and-wife.jpg"
                  alt="Ehemann und -Frau"
                  className="h-full cursor-pointer rounded shadow transition hover:scale-105 hover:shadow-lg"
                />
              </div>
            </div>
          </Container>
        </div>

        <Modal
          isVisible={isFatherAndSonModalVisible}
          onClose={() => setIsFatherAndSonModalVisible(false)}
        >
          <StaticImage
            src="../images/father-and-son.jpg"
            alt="Vater und Sohn"
            className="aspect-[2/3] h-full rounded shadow"
          />
        </Modal>
        <Modal
          isVisible={isWeddingModalVisible}
          onClose={() => setIsWeddingModalVisible(false)}
        >
          <StaticImage
            src="../images/wedding.jpg"
            alt="Hochzeit"
            className="aspect-[2/3] h-full rounded shadow"
          />
        </Modal>
        <Modal
          isVisible={isFatherAndBrotherModalVisible}
          onClose={() => setIsFatherAndBrotherModalVisible(false)}
        >
          <StaticImage
            src="../images/father-son-and-brother.jpg"
            alt="Vater, Sohn und Bruder"
            className="aspect-[2/3] h-full rounded shadow"
          />
        </Modal>
        <Modal
          isVisible={isHusbandAndWifeModalVisible}
          onClose={() => setIsHusbandAndWifeModalVisible(false)}
        >
          <StaticImage
            src="../images/husband-and-wife.jpg"
            alt="Ehemann und -Frau"
            className="aspect-[3/2] h-full rounded shadow"
          />
        </Modal>

        {/* Obituary */}
        <Container>
          <div className="flex w-full justify-center">
            <Button
              icon={<BiLinkExternal />}
              linkTo="https://www.landesschachbundbremen.de/images/22020/Rust-Lux_Nachruf_Armin_Sch%C3%B6ne-1.pdf"
            >
              Nachruf der Schachfreunde
            </Button>
          </div>
        </Container>

        {/* Footer */}
        <Container>
          <div className="space-y-6 border-t border-stone-400 pb-16 pt-10" />
        </Container>
      </div>
    </main>
  );
};

export default IndexPage;
