import classnames from 'classnames';
import * as React from 'react';

type ButtonProps = {
  theme?: 'light' | 'dark';
  linkTo?: string;
  icon?: React.ReactNode;
  children: React.ReactNode;
};

const Button: React.FC<ButtonProps> = ({
  theme = 'light',
  linkTo,
  icon,
  children,
}) => (
  <a
    href={linkTo}
    className={classnames(
      'cursor-pointer rounded-sm border px-8 py-3 text-base shadow transition hover:shadow-md',
      {
        'border-stone-400 bg-white text-stone-600 hover:border-stone-600 hover:text-stone-800':
          theme === 'light',
        'border-stone-700 bg-stone-800 text-stone-100 hover:border-stone-600 hover:text-stone-100':
          theme === 'dark',
      }
    )}
  >
    <span>
      {React.isValidElement(icon) &&
        React.cloneElement(icon, {
          className: 'mr-2.5 inline-block translate-y-[-1px] text-lg',
        })}
      {children}
    </span>
  </a>
);

export default Button;
