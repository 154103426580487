import * as React from 'react';

const CV: React.FC = () => (
  <>
    <p>
      Meinem Vater Armin Joachim Schöne (1932-2020) lag mein Fortkommen und
      Wohlergehen stets sehr am Herzen. Unser Verhältnis war umso herzlicher, je
      älter wir beide wurden.
    </p>
    <p>
      Er wurde am 15. Juni 1932 in Reichenbach in Niederschlesien als drittes
      von 4 Kindern geboren. Die Vorfahren nach beiden elterlichen Seiten
      stammten aus Oberschlesien aus der Region Beuthen (polnisch Bytom). Meine
      Großeltern betrieben damals ein Süßwarengeschäft. Mein Vater hatte einen
      älteren Halbbruder mütterlicherseits, Erich, und die zwei älteren Brüder
      Hanns-Detlev und Hartwig, an denen er sehr gehangen hatte. Hanns-Detlev
      ist im Krieg gefallen. 1934 wurde sein jüngerer Bruder Jürgen geboren, der
      heute in Erftstadt lebt.{' '}
    </p>
    <p>
      Mein Großvater Rochus war hauptamtlicher Bürgermeister mehrerer
      schlesischer Städte. Die Familie zog oft um und lebte in Reichenbach,
      Breslau, Silberberg, Hausdorf bei Neurode und Stahlhammer.{' '}
    </p>
    <p>
      1938 wurde mein Vater in Hausdorf eingeschult. Ab 1941 besuchte er das
      Gymnasium in Tarnowitz. Seine Freude am Schachspiel hat er bereits in
      dieser Zeit entdeckt. Mein Großvater spielte ebenfalls Schach und
      organisierte Turniere. Die Kindheit in Schlesien war unbeschwert,
      abgesehen vom Verlust des Bruders.
    </p>
    <p>
      Die Familie floh vor der vorrückenden Roten Armee Anfang 1945 nach Bayern.
      In Bergheim bei Augsburg fand sie eine Bleibe und baute in Eigenarbeit mit
      einfachen Mitteln ein kleines Haus. In der Zeit verstarb der Bruder
      Hartwig. Die Familie brachte sich wie damals üblich auf abenteuerliche
      Weise durch.{' '}
    </p>
    <p>
      Mein Vater erwarb 1950 in Augsburg das Abitur und studierte danach
      Maschinenbau an der TU München. 1954 schloss er das Studium als
      Jahrgangsbester ab. Unterhalt und Studiengebühren verdiente er durch
      Arbeiten auf dem Bau und später als studentische Hilfskraft bei Prof.
      Gustav Niemann.{' '}
    </p>
    <p>1952 wurde er Schachmeister von Bayrisch-Schwaben.</p>
    <p>
      Anfang 1955 trat er eine Stelle bei der Bayer AG in Leverkusen an. Dort
      blieb er in leitenden Stellungen bis Ende 1969. Er befasste sich mit
      Verfahrensentwicklungen und war später ein Pionier bei der Einführung der
      Prozessrechentechnik bei Bayer.
    </p>
    <p>
      1966 wurde er als Externer an der RWTH Aachen bei Prof. Otto Schäfer mit
      der Arbeit „Das dynamische Verhalten von Wärmeaustauschern und seine
      Beschreibung durch Näherungen“ mit der Note „sehr gut“ zum Dr.-Ing.
      promoviert. Seine anschließende Habilitation schloss er 1969 mit der
      Arbeit „Prozessrechensysteme in der Verfahrensindustrie“ ab, diese
      erreichte als Fachbuch 2 Auflagen.
    </p>
    <p>
      Während dieser Zeit spielte er beim Schachverein Opladen und wurde einmal
      Mittelrhein-Meister.{' '}
    </p>
    <p>
      Die Großeltern waren Ende der 50er Jahre nach Hoengen bei Aachen gezogen.
      Mein Großvater lebte bis 1959, die Großmutter bis in die 70er Jahre.
    </p>
    <p>
      Im Jahr 1961 heiratete mein Vater in Köln meine Mutter Monika. Sie war als
      Diplomkaufmann auf seinen Wunsch nicht mehr berufstätig und half ihm beim
      beruflichen Fortkommen, später widmete sie sich hingebungsvoll der
      Kindererziehung. 1963 wurde ich geboren und 1968 mein Bruder Egbert. Meine
      Eltern trennten sich 1992 und ließen sich 1997 scheiden.
    </p>
    <p>
      1969-1971 war er Geschäftsführer einer Vorläufergesellschaft der
      Elastogran-Gruppe in Lemförde. Gleichzeitig veröffentlichte er das
      Handbuch „Simulation Technischer Systeme“ als Herausgeber bei Hanser. Er
      hat insgesamt 19 Bücher verfasst, davon 5 in seinem 9. Lebensjahrzehnt,
      diese 5 mit historischen Themen, die anderen 14 mit technischen.
    </p>
    <p>
      Von 1974 bis 1983 war er Professor an der Fachhochschule Bielefeld. Aus
      seiner Lehre dort ist das Lehrbuch „Digitaltechnik und Mikrorechner“
      hervorgegangen. Um 1978 herum gründete er mit Mitstreitern den Verein für
      Denkmalpflege Lemförde e.V., erreichte, dass Burg und Ortskern in Lemförde
      unter Denkmalschutz gestellt wurden und vermittelte dem Ort große Mittel
      aus dem Städtebauförderungsprogramm.
    </p>
    <p>
      Währenddessen spielte er beim Schachverein Osnabrück und war mehrfach
      Osnabrücker Stadtmeister, später bei Spenge-Enger und Rochade Bielefeld,
      dort in der Mannschafts-Bundesliga.
    </p>
    <p>
      1983 folgte er einem Ruf an die Universität Bremen auf die Professur für
      Meß-, Regelungs- und Systemtechnik und war seit 1985 bis zu seiner
      Emeritierung 1997 Leiter des gleichnamigen Hochschulinstitutes. Er
      gründete die Deutsche Gesellschaft für Meß-, Regelungs- und Systemtechnik
      e.V., abgekürzt DFMRS, eine Mitgliedsvereinigung der AiF, und erreichte
      so, dass umfangreiche Fördermittel für angewandte Forschung das Land
      Bremen bis heute erreichen. Bis zu seinem 80. Lebensjahr war er Präsident
      und seitdem Ehrenpräsident dieser Gesellschaft. Er gründete das Friedrich
      Wilhelm Bessel-Institut gGmbH als Tochtergesellschaft der DFMRS und
      leitete dieses als Geschäftsführer. Ein Schwerpunkt war die Regelung von
      Windenergieanlagen, denn er war stets der Meinung, dass der hohe Verbrauch
      fossiler Energie eingeschränkt werden müsse.
    </p>
    <p>
      In Bremen hat mein Vater bei der Bremer Schachgesellschaft von 1877
      gespielt. Seine höchste ELO-Zahl war 2201, das wird heutzutage bei
      Turnieren in die Kategorie Nationaler Meister eingeordnet. Die letzte
      ELO-Zahl war 2109. Er spielte sehr gerne an offenen Schachturnieren wie
      dem in Biel mit, später bei offenen Seniorenmeisterschaften, mindestens
      eine hat er gewonnen. Die Schach-Datenbanken im Internet geben umfassend
      über seine Partien und seinen Spielstil Auskunft.
    </p>
    <p>
      1997 heiratete er seine zweite Frau Helga, eine musikalische Norddeutsche.
      Diese wurde zur Großmutter meiner 4 Kinder, diese sind seine Enkel. Sie
      zogen in ihr neu gebautes Haus in Langwedel. Bei den gemeinsamen Reisen
      ließ er keine Kirche aus, was dazu führte, dass seine Frau ihn zwang,
      wieder in die Kirche einzutreten.
    </p>
    <p>
      Wie zuvor in Lemförde begann mein Vater, die Geschichte des neuen
      Wohnortes zu untersuchen und entdeckte dabei die Lage der Langwedeler Burg
      wieder. Seit seinem 80. Lebensjahr hat er sich umfassend in die
      Regionalgeschichte dieser Region seit etwa dem Spätmittelalter über den
      30jährigen Krieg bis ins 19. Jahrhundert eingearbeitet und während dieser
      Zeit 5 Werke verfasst, darunter 2 Biographien, nämlich über Friedrich von
      der Decken und den letzten Grafen Friedrich von Diepholz.
    </p>
    <p>
      Er war eine prägende Persönlichkeit. Was er tat, tat er voller Überzeugung
      und mit Nachdruck. Er war der Meinung, dass der Mensch auf dieser Welt
      etwas leisten müsse. Seine Vorstellungen und sein Vorbild mögen
      überdauern!
    </p>
    <p className="text-right italic">Heralt Schöne</p>
  </>
);

export default CV;
