import * as React from 'react';

const ShortProfile: React.FC = () => (
  <>
    <p>Armin Schöne war ein Mann mit vielen Interessen und Facetten.</p>
    <p>
      Er war ein Familienmensch als Vater von zwei und Großvater von 4 Kindern.
    </p>
    <p>
      Er war ein Professor, der für die Forschung gelebt hat, von Wissensdrang
      angetrieben wurde und mit viel Engagement den Aufbau seines Fachgebiets an
      der Universität Bremen vorangetrieben hat.
    </p>
    <p>
      Er war ein Manager, der Strukturen, Netzwerke und Fachgesellschaften
      geschaffen hat und mit viel Energie und Überzeugungsarbeit Mitstreiter
      gewonnen hat.
    </p>
    <p>
      Er war ein Schachprofi, der mit seinen originellen Eröffnungsgedanken
      seine Mitspieler begeisterte und aussichtslos erscheinende Partien wenden
      konnte, wodurch er legendär wurde.{' '}
    </p>
    <p>
      Er war ein Historiker, der sich auch noch im Ruhestand intensiv mit der
      Geschichte rund um die Stadt Bremen bis ins frühe Mittelalter beschäftigte
      und viele weiße Flecken der Historie ausfüllte.
    </p>
    <p>
      Er war ein Autor, der sich durch die Veröffentlichung von 19 Büchern und
      über 400 Aufsätzen in den Bereichen Mess- und Systemtechnik, Informatik
      und Geschichte hervorgetan hat.
    </p>
  </>
);

export default ShortProfile;
